.Toastify__toast {
    border-radius   : 5px;
}

.Toastify__toast--error {
    background      :   #d32f2f;
}

.Toastify__toast--success {
    border-radius   : 5px;
    background      : green;
}